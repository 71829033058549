<template>
  <div class="tabbar">
    <van-tabbar v-model="active" fixed router >
      <van-tabbar-item
        v-for="(item, index) in tabData"
        :icon="item.icon"
        :to="item.path"
        :key="index"
        @click="handleChange(index)">
        <p :class="defaultActive === index ? active:''" style="text-align: center;">{{item.title}}</p>
        <p :class="defaultActive === index ? active:''" style="text-align: center;margin-top: 4px;">{{item.title1}}</p>
        <template slot="icon" slot-scope="props">
          <img :src="props.active ? item.active : item.normal">
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
 
<script>
export default {
  name: 'TabBar',
  props: {
    defaultActive: {
      type: Number,
      default: 0
    },
    tabData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: this.defaultActive
    }
  },
  methods: {
    handleChange(val) {
      this.$emit('change', val);
    }
  }
}
</script>
 
<style scoped>
.van-tabbar{
  padding-top: 4px;
}
.active_tab img {
  width: 3rem;
  height: 3rem;
}
.van-tabbar-item--active {
  color: #0E5CD8;
}
</style>